import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import OverView from "@/views/Admin/Persons/V4/PrivatePOIs/Create/Overview.vue";
import { isNull, isUndefined } from "lodash";
import {
	Paginated,
	SortingOption,
	ResultPaginate,
} from "@/interfaces/paginated";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "CreatePrivatePOIs",

	props: {},

	components: { Alertize, OverView },

	data: () => ({
		title: "CreatePrivatePOIs",
		params_privatePois: {
			paginated: { page: 1, limit: 25 } as Paginated,
			filters: {},
			options: { sort: "id", order: "desc" } as SortingOption,
		},
		interval: null,
		ModalValidFile: false,
	}),

	created() {
		this.$nextTick(async () => {
			await this.setLoadingData(TypeLoading.loading);
			await this.fetchPrivatePOIs();
			await this.setLoadingData();
		});
	},

	async mounted() {
		this.interval = setInterval(() => {
			this.dispatchPrivatePOIs();
		}, 30000);
	},

	beforeDestroy() {
		clearInterval(this.interval);
	},

	computed: {
		...mapGetters("privatePois", ["getResultPaginatedPrivatePOIs"]),

		getEntities() {
			const result: ResultPaginate = this.getResultPaginatedPrivatePOIs;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("proccess", [
			"setLoadingField",
			"setNotification"
		]),

		...mapActions("privatePois", ["paginatedPrivatePois"]),

		downloadExample() {
			window.location.href = "/template_privatepois.csv";
		},

		showModalValidFile(params: any) {
			this.ModalValidFile = params;
		},

		updatePaginate(data: any) {
			this.params_privatePois.paginated.page = data;
		},

		async fetchPrivatePOIs() {
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchPrivatePOIs();
			await this.setLoadingData();
		},

		async dispatchPrivatePOIs() {
			return await this.paginatedPrivatePois(this.params_privatePois);
		},

		setFilter(params: { key: string | number; value: any }) {
			this.params_privatePois.filters = {};
			this.params_privatePois.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},

		setOrder(params: any) {
			this.params_privatePois.options = {};
			this.params_privatePois.options = {
				sort: params.sort,
				order: params.order,
			};
		},

		async selectedOption(params: any) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.setOrder(params.options);
			this.updatePaginate(1);
			await this.fetchPrivatePOIs();
		},

		async removeOption() {
			this.params_privatePois.filters = {};
			this.params_privatePois.options = { sort: "id", order: "desc" };
			this.updatePaginate(1);
			await this.fetchPrivatePOIs();
		},
	},

	watch: {
		"params_privatePois.paginated.page"(val, old) {
			if (val !== old) {
				this.dispatchPrivatePOIs();
			}
		},
	},
});
