
import Vue from "vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import TableUpload from "@/views/Admin/Persons/V4/PrivatePOIs/Table/TableUpload.vue";
import { isUndefined, isNull, isEmpty, filter } from "lodash";
//@ts-ignore
import { isRequired } from "@/services/rule-services";
import { mapActions } from "vuex";
//@ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
//@ts-ignore
// import { parseCsvToJson, verifyRequiredFields } from "@/utils/file";
import {
	preparedColumnDefsGrid,
	//@ts-ignore
} from "@/utils/CustomizeViewData";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const KEY_COUNTRY_SERVICE = "countries";

export default Vue.extend({
	name: "Overview",

	props: {
		private_pois_list: {
			type: Array,
			required: true,
		},
		result_page: {
			type: Object,
			default: function () {
				return {};
			},
		},
		limit: {
			type: Number,
			default: 25,
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},

	components: {
		CardTextField,
		TableUpload,
		CardAutocomplete,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
	},

	data: () => ({
		valid: false,
		form: {
			country_code: undefined,
			name_layer: "",
			file: null,
		},
		rules: {
			rule_private_name: [],
			rule_country_code: [],
			rule_file: [],
		},
		resources: {
			countries: [],
		},
		file: {
			uploaded: false,
			isValid: false,
			messages: [] as Array<string>,
		},
		context: null,
		selectionRows: "single",
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {
			await this.fetchDataCountry();
			this.context = { componentParent: this };
		});
	},

	computed: {

		prepareTableHeaders() {
			return [
				{
					text: "Id",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "id",
				},
				{
					text: "Country",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "country",
				},
				{
					text: "Layer Name",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "layer_name",
				},
				{
					text: "Total Count POIs",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "total_count_pois",
				},
				{
					text: "Status",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "status",
				},
				{
					text: "Error POIs",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "error_pois",
				},
				{
					text: "Create Date",
					align: "start",
					sortable: false,
					api_sortable: true,
					filterable: true,
					value: "create_date",
				},
			];
		},

		prepareTableContent() {
			const private_pois = this.private_pois_list;
			if (isUndefined(private_pois) || isNull(private_pois)) return [];
			return private_pois.map((privatePOIs: any) => {
				return {
					id: privatePOIs.id,
					country: privatePOIs.country_name,
					layer_name: privatePOIs.layer_name,
					total_count_pois: privatePOIs.total_pois,
					status: privatePOIs.status_name,
					error_pois: privatePOIs.total_errors,
					create_date: privatePOIs.created_at,
				};
			});
		},

		getRules() {
			return {
				isRequired,
			};
		},

		getTotalPrivatePois() {
			return this.$t("show.privatepois", {
				total: this.private_pois_list.length,
			});
		},

		getResultPage() {
			return this.result_page;
		},

		getConfigColumnDef() {
			return {
				context: this.context,
				entityName: "Private POIS",
				eventActions: false,
				flex: 1,
				resizableID: true,
			};
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.prepareTableHeaders,
				this.getConfigColumnDef
			);
		},

		gerRowsData() {
			if (isEmpty(this.table.items)) {
				return [];
			} else {
				return this.table.items;
			}
		},

		getItemsCountries(){
			return this.resources.countries;
		}

	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("proccess", ["setLoadingField", "setNotification"]),

		...mapActions("privatePois", ["fetchCountry", "createPrivatePOIs"]),

		showModalValidFile(params: Boolean) {
			this.$emit("show-modal-valid-file", params);
		},

		getFilterEtiquetaAsset() {
			return ".csv";
		},

		updatePaginate(data: any) {
			this.$emit("update-paginate", data);
		},

		clearRules() {
			this.rules.rule_private_name = [];
			this.rules.rule_country_code = [];
			this.rules.rule_file = [];
		},

		clearField() {
			this.form.name_layer = "";
			this.form.file = null;
		},

		async createFormData() {
			let formData = new FormData();

			formData.append("layer_name", this.form.name_layer);
			formData.append("country_code", this.form.country_code);
			formData.append("file", this.form.file);

			return formData;
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async addRules() {
			this.rules.rule_private_name = [this.getRules.isRequired];
			this.rules.rule_country_code = [this.getRules.isRequired];
			this.rules.rule_file = [this.getRules.isRequired];
		},

		async handleSubmit() {
			try {
				await this.addRules();

				if (!(await this.validate())) return;

				await this.setLoadingData(TypeLoading.send);

				await this.dispathCreatePrivatePOIs();
				this.clearRules();
				this.clearField();
				//this.showNotification(this.getMsgSuccess());
				await this.fetchPrivatePOIs();
				await this.setLoadingData();
			} catch (error) {
				this.clearRules();
				await this.setLoadingData();
			}
		},

		async fetchPrivatePOIs() {
			this.$emit("update-list-privatePOIs");
		},

		async fetchDataCountry(){
			await this.setLoadingData(true);
			this.resources.countries = await this.fetchCountry({type: KEY_COUNTRY_SERVICE});
			await this.setLoadingData();
		},

		async dispathCreatePrivatePOIs() {
			await this.createPrivatePOIs(await this.createFormData());
		},

		showNotification(notification: Notification) {
			return this.setNotification(notification);
		},

		getMsgSuccess() {
			return {
				title: "Success",
				type: MessageTypes.SUCCESS,
				show: true,
				btn_text: "Aceptar",
			};
		},

		async handleUpload(event: any) {
			if (!isNull(event)) {
				// this.file = { uploaded: false, isValid: false, messages: [] };
				// const fields: Array<string> = await parseCsvToJson(event);
				// const { isValid, messages } = await verifyRequiredFields(fields);
				// this.file = { uploaded: true, isValid, messages };
				this.file = { uploaded: true, isValid: true, messages: [] };
			}
		},

		handleUploadClear() {
			this.file = { uploaded: false, isValid: false, messages: [] };
		},

		selectedOption(params: any) {
			this.$emit("selected-option", params);
		},
		removeOption() {
			this.$emit("remove-option");
		},
	},
});
