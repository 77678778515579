 
import Vue from "vue";
// @ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import { SortingOption } from "@/interfaces/paginated";

export default Vue.extend({
	name: "TableUpload",

	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default() {
				return []
			}
		},
		limit: {
			type: Number,
			default: 25,
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},

	components: { Filterable },
	data: () => ({
		T_FILTRO: {
			id: "id",
			layer_name: "layer_name",
			file_name: "file_name",
			total_count_pois: "total_pois",
			error_pois: "total_errors",
			status: "status.description",
			country: "country_name",
			create_date: "created_at",
		}
	}),
	created() {},
	mounted() {},
	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
	},
	methods: {
		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},
		getOptions(params: SortingOption){
			return {
				sort: this.T_FILTRO[params.sort],
				order: params.order,
			}
		},
		selectedOption(params: { options: SortingOption, filter: any }) {
			this.$emit("selected-option", {
				options: this.getOptions(params.options),
				filter: params.filter,
				key: params.options.sort
			});
		},
		removeOption() {
			this.$emit("remove-option");
		},
	},
});
